<template>
  <div class="mrow mabout">
    <div class="wrapper">
      <ul>
        <li class="l1">
          <div class="mabout-img">
            <img src="../../../assets/images/icon01.png" />
          </div>
          <h3 class="mabout-tit">地理区位</h3>
          <div class="mabout-txt">
            产业园位于石家庄市高新技术产业园开发区，开发区是1991年3月经国务院批准设立的首批国家级高新区之一，主要以发展生物制药、电子信息、先进制造业等高新技术产业为主，建成集商贸、科研、文教、生活、居住和文化娱乐为一体的现代化新市区，在全国157个国家级高新区中，综合排名第17位。
          </div>
        </li>
        <li class="l2">
          <div class="mabout-img">
            <img src="../../../assets/images/icon02.png" />
          </div>
          <h3 class="mabout-tit">目标愿景</h3>
          <div class="mabout-txt">
            产业园将发挥国家级人力资源服务产业园引领作用，强化人力资源协同效应，进一步促进产业发展。以集聚化产业园、平台化产业园、生态化产业园为发展模式，实现产业集聚，物理空间共享向完整产业链、数字化驱动，开放、协同、共生、共赢的生态化产业系统发展，产业园之于区域经济，之于城市，融合创新。
          </div>
        </li>
        <li class="l3">
          <div class="mabout-img">
            <img src="../../../assets/images/icon03.png" />
          </div>
          <h3 class="mabout-tit">功能定位</h3>
          <div class="mabout-dw">
            <div class="item">
              <div class="img">
                <img src="../../../assets/images/icon04.png" />
              </div>
              <h4>
                一站式<br />
                公共服务
              </h4>
            </div>
            <div class="item">
              <div class="img">
                <img src="../../../assets/images/icon05.png" />
              </div>
              <h4>
                立体化<br />
                多层次的<br />
                人才系统开发
              </h4>
            </div>
            <div class="item">
              <div class="img">
                <img src="../../../assets/images/icon06.png" />
              </div>
              <h4>
                人力资源服务<br />
                创新孵化
              </h4>
            </div>
            <div class="item">
              <div class="img">
                <img src="../../../assets/images/icon07.png" />
              </div>
              <h4>
                国际<br />
                交流与合作
              </h4>
            </div>
            <div class="item">
              <div class="img">
                <img src="../../../assets/images/icon08.png" />
              </div>
              <h4>
                人力资源<br />
                信息化服务
              </h4>
            </div>
            <div class="item">
              <div class="img">
                <img src="../../../assets/images/icon09.png" />
              </div>
              <h4>
                人力资源<br />
                服务机构聚集
              </h4>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
