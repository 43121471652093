<template>
  <div class="mrow mhonour">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">荣誉</h2>
        <div class="en">honour</div>
        <div class="line"></div>
      </div>
      <div class="mhonour-list">
        <ul>
          <li v-for="item in pictureList"
              :key="item.id">
            <a>
              <img :src="item.pic" />
              <h3 class="tit">{{ item.title }}</h3>
            </a>
          </li>
        </ul>
      </div>
      <div class="gjrcc-btn">
        <a class="mfullbtn"
           @click="toRouter('apply',{cn:'入驻申请',en:'Application for residence',type:1})">
          <img src="../../../assets/images/icon32.png" />入驻申请</a>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      pictureList: [],
    };
  },
  mounted () {
    this.getPictureList();
  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };
      this.newRouter('/' + routerName, query)
    },
    getPictureList () {
      this.api
        .getPictureList({ pageNo: 1, pageSize: 50, type: 4 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.pictureList = records;
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
