<template>
  <div class="mbanner">
    <div class="wrapper">
      <div class="mbanner-hd">
        <div class="cn"><h2>园区简介</h2></div>
        <div class="en">Introduction</div>
      </div>
      <div class="mbanner-bd">
        产业园位于石家庄市高新区长江大道 315
        号，具有一站式公共服务平台、人力资源服务机构集聚平台、<br />
        创新创业孵化平台、立体化人才系统开发平台、人力资源信息化服务平台、<br />
        国际交流与合作平台六大功能，旨在集聚和发展培训、猎头、管理咨询、测评等中高端业态，<br />
        重点向高端价值链发展，拓展人力资本产业，<br />
        为机构赋能、为人才赋能，打造国际化人力资源服务产业园。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner01.jpg);
}
</style>
