<template>
  <div class="mrow machiev">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">成果</h2>
        <div class="en">achievement</div>
        <div class="line"></div>
      </div>
      <div class="machiev-txt">
        产业园成立以来，先后在藁城区、鹿泉区、栾城区、高邑县、深泽县、井陉县设立人力资源服务工作站。<br />
        助力区域经济高质量发展，构建了“产业聚人才、人才促发展”的良好格局。
      </div>
      <div class="machiev-list">
        <ul>
          <li
            class="l1"
            v-for="item in listTop"
            :key="item.id"
            :style="{ backgroundImage: 'url(' + item.labelBackgroundMap + ')' }"
          >
            <div class="hd">
              <img :src="item.labelIcon" /><big>{{ item.labelValue }}</big
              ><span>{{ item.labelUnit }}</span>
            </div>
            <div class="bd">{{ item.labelContent }}</div>
          </li>
        </ul>
        <ul class="u1">
          <li
            class="l1"
            v-for="item in listCenter"
            :key="item.id"
            :style="{ backgroundImage: 'url(' + item.labelBackgroundMap + ')' }"
          >
            <div class="hd">
              <img :src="item.labelIcon" /><big>{{ item.labelValue }}</big
              ><span>{{ item.labelUnit }}</span>
            </div>
            <div class="bd">{{ item.labelContent }}</div>
          </li>
        </ul>
        <ul>
          <li
            class="l1"
            v-for="item in listBottom"
            :key="item.id"
            :style="{ backgroundImage: 'url(' + item.labelBackgroundMap + ')' }"
          >
            <div class="hd">
              <img :src="item.labelIcon" /><big>{{ item.labelValue }}</big
              ><span>{{ item.labelUnit }}</span>
            </div>
            <div class="bd">{{ item.labelContent }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listTop: [],
      listCenter: [],
      listBottom: [],
    };
  },
  mounted() {
    this.getAchievementsList();
  },
  methods: {
    getAchievementsList() {
      this.api
        .getAchievementsList({ pageNo: 1, pageSize: 50, labelType: 1 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.listTop = records.slice(0, 6);
          this.listCenter = records.slice(6, 11);
          this.listBottom = records.slice(11, 20);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
