<template>
  <div class="sidebar">
    <ul>
      <li>
        <a @click="toRouter('exhibition')">
          <img src="../../../assets/images/sidebar01.png" />
          <h3>公共展示</h3>
        </a>
      </li>
      <li>
        <a @click="toRouter('activitynews')">
          <img src="../../../assets/images/sidebar02.png" />
          <h3>活动新闻</h3>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toRouter(param) {
      _MEIQIA("init");
      // _MEIQIA("showPanel");

      // newRouter(param);
    },
  },
};
</script>
<style scoped lang="scss"></style>
