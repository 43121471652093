<template>
  <div class="introductionPark">
    <banner />
    <contents />
    <achievements />
    <honor />
    <sidebar />
  </div>
</template>

<script>
import banner from "./banner.vue";
import contents from "./contents.vue";
import achievements from "./achievements.vue";
import honor from "./honor.vue";
import sidebar from "./sidebar.vue";

export default {
  data() {
    return {
      msg: "el_introductionPark",
    };
  },
  components: {
    banner,
    contents,
    achievements,
    honor,
    sidebar,
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
